<template>
  <div v-if="isLoading">
    <v-skeleton-loader
      type="table-heading"
      class="mb-8"
    />
    <v-row>
      <v-col
        v-for="loadingIdx of 36"
        :key="`loading${loadingIdx}`"
        md="3"
        sm="3"
        cols="4"
        class="my-2 d-flex align-center justify-center"
      >
        <v-skeleton-loader
          type="avatar"
          size="60"
        />
      </v-col>
    </v-row>
  </div>

  <div v-else-if="!isLoading && date">
    <v-menu
      v-if="!isSimpleMode"
      v-model="isPickingDate"
      :close-on-content-click="false"
      transition="scale-transition"
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          :value="date"
          :prepend-inner-icon="icons.mdiCalendar"
          readonly
          class="text-base pa-0 ma-0 px-4"
          v-bind="attrs"
          v-on="on"
        />
      </template>

      <v-date-picker
        v-model="date"
        locale="ja"
        :day-format="date => new Date(date).getDate()"
        color="primary"
        no-title
        @change="isPickingDate = false"
      />
    </v-menu>

    <v-tabs
      v-model="currentTabIdx"
      show-arrows
      background-color="transparent"
      class="mb-5 elevation-0"
    >
      <v-tab
        v-for="role in roles"
        :key="`v-tab-${role[0]}`"
      >
        <v-badge
          v-if="workingUsersByRole[role[0]] && workingUsersByRole[role[0]].length > 0"
          :content="workingUsersByRole[role[0]].length"
        >
          {{ role[1] }}
        </v-badge>

        <span v-else>{{ role[1] }}</span>
      </v-tab>
    </v-tabs>

    <simple-clock-in
      class="px-4"
      @is-on="isSimpleMode = $event"
    />

    <v-tabs-items
      v-model="currentTabIdx"
      style="background: transparent"
      touchless
      class="pt-4 px-1"
    >
      <v-tab-item
        v-for="role in roles"
        :key="`v-tab-item-${role[0]}`"
      >
        <v-row>
          <v-col
            v-for="user of usersByRole[role[0]]"
            :key="`timecard_for_user_${user.id}`"
            md="3"
            sm="3"
            cols="4"
            class="my-2"
          >
            <div class="d-flex flex-column align-center mb-4">
              <v-badge
                :color="timecardsByUserId[+user.id] ? 'success' : 'secondary'"
                overlap
                offset-x="15"
                offset-y="15"
                bordered
              >
                <v-hover v-slot="{ hover }">
                  <v-avatar
                    size="60"
                    :class="{ 'elevation-4' : hover, 'is-working': timecardsByUserId[+user.id] }"
                    class="cursor-pointer"
                    color="secondary"
                    @click="openTimecardDialog(user)"
                  >
                    <v-img
                      v-if="user.attributes.profileImage"
                      :src="user.attributes.profileImage"
                    />
                    <span
                      v-else
                      class="text-xs font-weight-bold"
                    >
                      {{ user.attributes.name.substring(0, 3) }}
                    </span>
                  </v-avatar>
                </v-hover>
              </v-badge>

              <div class="mt-2 d-flex flex-wrap justify-center align-center">
                <span>
                  {{ user.attributes.name }}
                </span>

                <transition name="fadeLeft">
                  <v-switch
                    v-if="isSimpleMode"
                    :input-value="!!timecardsByUserId[+user.id]"
                    color="success"
                    hide-details
                    inset
                    class="pa-0 ma-0 mx-auto pl-4"
                    style="animation-duration: 0.3s;"
                    @click="clockInSimply({
                      timecardId: (timecardsByUserId[+user.id] || {}).id,
                      userId: user.id
                    })"
                  />
                </transition>
              </div>

              <p
                v-if="timecardsByUserId[+user.id] && timecardsByUserId[+user.id].attributes && timecardsByUserId[+user.id].attributes.startAt"
                class="text-xs secondary--text mt-2"
              >
                {{ format(parseISO(timecardsByUserId[+user.id].attributes.startAt), 'M/d HH:mm ~') }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog
      v-model="isTimecardDialogOpen"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="d-flex">
          <v-badge
            :color="timecardsByUserId[+targetUser.id] ? 'success' : 'secondary'"
            overlap
            offset-x="15"
            offset-y="15"
            bordered
          >
            <v-avatar color="secondary">
              <v-img
                v-if="targetUser.attributes.profileImage"
                :src="targetUser.attributes.profileImage"
              />
              <span
                v-else
                class="text-xs font-weight-bold"
              >
                {{ targetUser.attributes.name.substring(0, 3) }}
              </span>
            </v-avatar>
          </v-badge>

          <div class="ml-6">
            {{ targetUser.attributes.name }}
          </div>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                ref="startAtInput"
                :value="currentTimecard.startAt ? hhmm(currentTimecard.startAt) : ''"
                label="IN"
                :error="!currentTimecard.startAt"
                type="time"
                :messages="currentTimecard.startAt ? format(parseISO(currentTimecard.startAt), 'M/d HH:mm') : ''"
                @change="
                  changeTime({ time: $event, key: 'startAt' });
                  currentTimecard.leaveAt = null;
                  clearAllBreakTimecards();
                "
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-if="currentTimecard.id && currentTimecard.startAt"
                ref="leaveAtInput"
                :value="currentTimecard.leaveAt ? hhmm(currentTimecard.leaveAt) : ''"
                label="OUT"
                :error="!!currentTimecard.leaveAt && (currentTimecard.leaveAt < currentTimecard.startAt)"
                type="time"
                :messages="currentTimecard.leaveAt ? format(parseISO(currentTimecard.leaveAt), 'M/d HH:mm') : ''"
                @change="changeTime({ time: $event, key: 'leaveAt', startAt: currentTimecard.startAt })"
              />
            </v-col>
          </v-row>

          <template v-if="!isAlliance && currentTimecard.id && currentTimecard.startAt">
            <v-row
              v-for="(breakTimecard, breakTimecardIdx) in currentTimecard.breakTimecardsAttributes"
              :key="`breakTimecards[${breakTimecardIdx}]`"
            >
              <v-col
                cols="12"
                class="mt-4 pb-0 text-base"
              >
                休憩
              </v-col>

              <v-col cols="6">
                <v-text-field
                  ref="breakTimeStartAtInput"
                  :value="breakTimecard.breakTimeStartAt ? hhmm(breakTimecard.breakTimeStartAt) : ''"
                  label="IN"
                  type="time"
                  :messages="breakTimecard.breakTimeStartAt ? format(parseISO(breakTimecard.breakTimeStartAt), 'M/d HH:mm') : ''"
                  @change="changeTime({
                    time: $event,
                    key: `breakTimecardsAttributes[${breakTimecardIdx}].breakTimeStartAt`,
                    startAt: currentTimecard.startAt,
                  })"
                />
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-if="breakTimecard.breakTimeStartAt"
                  ref="breakTimeEndAtInput"
                  :value="breakTimecard.breakTimeEndAt ? hhmm(breakTimecard.breakTimeEndAt) : ''"
                  label="OUT"
                  type="time"
                  :error="!!breakTimecard.breakTimeEndAt && (breakTimecard.breakTimeEndAt < breakTimecard.breakTimeStartAt)"
                  :messages="breakTimecard.breakTimeEndAt ? format(parseISO(breakTimecard.breakTimeEndAt), 'M/d HH:mm') : ''"
                  @change="changeTime({
                    time: $event,
                    key: `breakTimecardsAttributes[${breakTimecardIdx}].breakTimeEndAt`,
                    startAt: breakTimecard.breakTimeStartAt,
                  })"
                />
              </v-col>
            </v-row>
          </template>
        </v-card-text>

        <v-card-actions class="mt-4">
          <v-spacer />
          <v-btn
            color="primary"
            class="me-3"
            type="submit"
            :disabled="hasError"
            :ripple="false"
            @click.prevent="createOrUpdateTimecard()"
          >
            {{ currentTimecard.id ? '更新' : '作成' }}
          </v-btn>

          <v-btn
            text
            color="secondary"
            :ripple="false"
            @click="cancelForm"
          >
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isWorkSchedulesVisible"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          fab
          color="accent"
          fixed
          bottom
          right
          large
          :ripple="false"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>{{ icons.mdiCalendar }}</v-icon>
        </v-btn>
      </template>
      <work-schedules
        :closable="true"
        @close="isWorkSchedulesVisible = false"
      />
    </v-dialog>
  </div>

  <div v-else-if="!isLoading && !date">
    営業日の取得に失敗しました。<br />
    リロードしてください。
  </div>
</template>

<script>
import {
  ref,
  toRefs,
  getCurrentInstance,
  computed,
  inject,
} from '@vue/composition-api'
import {
  mdiClockOutline,
  mdiTilde,
  mdiCalendar,
} from '@mdi/js'
import {
  chain,
  findIndex,
  groupBy,
  isEmpty,
  each,
  update,
} from 'lodash'
import {
  addDays,
  parseISO,
  format,
  formatISO,
} from 'date-fns'
import UserApi from '@/api/waiter/User'
import TimecardApi from '@/api/waiter/Timecard'
import TimeApi from '@/api/v2/utility/Time'
import useValidation from '@/views/composable/useValidation'
import useTimecard from '@/views/composable/useTimecard'
import useCompInit from '@/views/composable/useCompInit'
import useDateFormat from '@/views/composable/useDateFormat'
import useCurrentData from '@/views/composable/useCurrentData'
import WorkSchedules from '@/views/components/calendar/WorkSchedules.vue'
import SimpleClockIn from '@/views/components/timecard/SimpleClockIn.vue'

export default {
  components: {
    SimpleClockIn,
    WorkSchedules,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const currentClubMeta = inject('currentClubMeta', { today: null })
    const getClub = inject('getClub', () => {})
    const date = ref(currentClubMeta.today)
    const isPickingDate = ref(false)
    const isSimpleMode = ref(false)
    const startAtInput = ref()
    const leaveAtInput = ref()
    const breakTimeStartAtInput = ref()
    const breakTimeEndAtInput = ref()
    const users = ref([])
    const targetUser = ref({
      attributes: {
        name: '',
        id: 0,
      },
    })
    const timecards = ref([])
    const currentTabIdx = ref(0)
    const currentTimecard = ref({})
    const isTimecardDialogOpen = ref(false)
    const isWorkSchedulesVisible = ref(false)
    const tmpStartAt = ref()

    const { isValidId } = useValidation()
    const { buildTimecardForm, buildTimecardEditForm, simpleCurrentTime } = useTimecard()
    const { isLoading, initWith } = useCompInit()
    const { hhmm } = useDateFormat()
    const { currentClub } = useCurrentData()

    const roles = [
      ['cast', 'キャスト'],
      ['waiter', 'ボーイ'],
      ['alliance', 'アライアンス'],
    ]
    const isAlliance = computed(() => roles[currentTabIdx.value][0] === 'alliance')

    const hasError = computed(() => {
      return startAtInput.value?.hasError
        || leaveAtInput.value?.hasError
        || breakTimeStartAtInput.value?.hasError
        || breakTimeEndAtInput.value?.hasError
    })

    const workingUserIds = computed(() => {
      return chain(timecards.value)
        .filter(timecard => isEmpty(timecard.attributes.leaveAt))
        .map(timecard => Number(timecard.attributes.userId))
        .value()
    })

    const workingUsersByRole = computed(() => {
      return chain(users.value)
        .filter(user => workingUserIds.value.includes(+user.id))
        .groupBy('attributes.role')
        .value()
    })

    const timecardsByUserId = computed(() => {
      const obj = {}

      each(timecards.value, o => {
        if (o.attributes.leaveAt) return

        obj[+o.attributes.userId] = o
      })

      return obj
    })

    const usersByRole = computed(() => {
      return groupBy(users.value, 'attributes.role')
    })

    const cancelForm = () => {
      isTimecardDialogOpen.value = false
      currentTimecard.value = {}
      tmpStartAt.value = null
    }

    const getUsers = async () => {
      const res = await UserApi.getUsers()

      if (res?.data) {
        // changed to array
        users.value = [...res.data.users.data]
      }
    }

    const getToday = async () => {
      const res = await TimeApi.getToday()

      if (res?.data) {
        date.value = res.data.today
      }
    }

    const createTimecard = async () => {
      const res = await TimecardApi.createTimecard(currentTimecard.value)
      if (res?.data) {
        timecards.value.push(res.data.timecard.data)
        vm.$toast.success(`${targetUser.value.attributes.name}を${isAlliance.value ? '待機' : '出勤'}中にしました`)
      }

      cancelForm()
    }

    const updateTimecard = async () => {
      const res = await TimecardApi.updateTimecard(currentTimecard.value)
      if (res?.data) {
        const timecard = res.data.timecard.data
        const timecardIdx = findIndex(timecards.value, o => Number(o.id) === Number(timecard.id))

        timecards.value.splice(timecardIdx, 1, timecard)

        vm.$toast.success(`${targetUser.value.attributes.name}の${isAlliance.value ? '待機中を解除しました' : '勤務状態を更新しました'}`)
      }

      cancelForm()
    }

    const getTimecards = async () => {
      const res = await TimecardApi.getTimecards({ isWorking: true })

      if (res?.data) {
        timecards.value = [...res.data.timecards.data]
      }
    }

    const createOrUpdateTimecard = () => {
      if (isValidId(currentTimecard.value.id)) {
        updateTimecard()
      } else {
        createTimecard()
      }
    }

    const calcBusinessTime = (time, startAt = null) => {
      let baseDate
      if (startAt) {
        baseDate = format(parseISO(startAt), 'yyyy-MM-dd')
      } else {
        baseDate = date.value
      }

      let newDatetime = parseISO(`${baseDate} ${time}`)
      const midnightDatetime = parseISO(`${baseDate} ${hhmm(parseISO(currentClub.value.openAt))}`)

      if ((startAt && midnightDatetime > newDatetime && parseISO(startAt) > newDatetime) || (!startAt && midnightDatetime > newDatetime)) {
        newDatetime = addDays(newDatetime, 1)
      }

      return newDatetime
    }

    const changeTime = ({ time, key, startAt = null }) => {
      const newDatetime = calcBusinessTime(time, startAt)

      update(currentTimecard.value, key, () => formatISO(newDatetime))
    }

    const clockInSimply = ({ timecardId = null, userId }) => {
      const now = simpleCurrentTime()
      targetUser.value = users.value.find(user => +user.id === +userId)

      if (timecardId) {
        const timecardData = timecardsByUserId.value[+userId]
        if (!timecardData) return

        currentTimecard.value = {
          ...buildTimecardEditForm(timecardData),
          leaveAt: now,
        }

        changeTime({ time: hhmm(now), key: 'leaveAt', startAt: currentTimecard.value.startAt })

        updateTimecard()
      } else {
        currentTimecard.value = buildTimecardForm({ userId, startAt: now })

        createTimecard()
      }
    }

    const openTimecardDialog = user => {
      targetUser.value = user
      const timecardData = timecardsByUserId.value[+targetUser.value.id]

      currentTimecard.value = timecardData
        ? buildTimecardEditForm(timecardData)
        : buildTimecardForm({
          userId: targetUser.value.id,
          startAt: formatISO(calcBusinessTime(hhmm(new Date()))),
        })

      if (currentTimecard.value?.startAt) {
        tmpStartAt.value = currentTimecard.value?.startAt
      }

      isTimecardDialogOpen.value = true
    }

    const clearAllBreakTimecards = () => {
      if (currentTimecard.value && currentTimecard.value.breakTimecardsAttributes) {
        each(currentTimecard.value.breakTimecardsAttributes, breakTimecard => {
          update(breakTimecard, 'breakTimeStartAt', () => null)
          update(breakTimecard, 'breakTimeEndAt', () => null)
        })
      }
    }

    initWith([
      getClub(),
      getUsers(),
      getTimecards(),
      getToday(),
    ])

    return {
      // data
      startAtInput,
      leaveAtInput,
      breakTimeStartAtInput,
      breakTimeEndAtInput,
      isLoading,
      currentTabIdx,
      users,
      isTimecardDialogOpen,
      targetUser,
      currentTimecard,
      timecards,
      workingUserIds,
      isWorkSchedulesVisible,
      tmpStartAt,
      roles,
      date,
      currentClubMeta,
      isPickingDate,
      isSimpleMode,

      // computed
      usersByRole,
      timecardsByUserId,
      isAlliance,
      hasError,
      workingUsersByRole,

      // methods
      openTimecardDialog,
      createTimecard,
      updateTimecard,
      createOrUpdateTimecard,
      cancelForm,
      hhmm,
      changeTime,
      clockInSimply,
      parseISO,
      format,
      clearAllBreakTimecards,

      icons: {
        mdiClockOutline,
        mdiTilde,
        mdiCalendar,
      },
    }
  },
}
</script>

<style scoped>
@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.4);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0,0,0,0.1);
    transform: translatey(-3px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.4);
    transform: translatey(0px);
  }
}
.is-working {
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
}
</style>
