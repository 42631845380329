import { map } from 'lodash'
import { formatISO, roundToNearestMinutes } from 'date-fns'

export default () => {
  const buildBreakTimecardForm = ({
    id = null,
    breakTimeStartAt = '',
    breakTimeEndAt = '',
    _destroy = false,
  }) => {
    return {
      id,
      breakTimeStartAt,
      breakTimeEndAt,
      _destroy,
    }
  }

  const buildTimecardForm = ({
    id = null,
    startAt = '',
    leaveAt = '',
    userId = null,
    breakTimecardsAttributes = [],
  }) => {
    const form = {
      id,
      startAt,
      leaveAt,
      userId,
      breakTimecardsAttributes,
    }

    if (form.breakTimecardsAttributes.length < 1) {
      form.breakTimecardsAttributes.push(buildBreakTimecardForm({}))
    }

    return form
  }

  const buildTimecardEditForm = timecardData => {
    const {
      startAt,
      leaveAt,
      userId,
      breakTimecards,
    } = timecardData.attributes

    const breakTimecardsAttributes = map(breakTimecards.data, breakTimecard => {
      const { breakTimeStartAt, breakTimeEndAt } = breakTimecard.attributes

      return buildBreakTimecardForm({
        id: breakTimecard.id,
        breakTimeStartAt,
        breakTimeEndAt,
      })
    })

    const form = buildTimecardForm({
      id: timecardData.id,
      startAt,
      leaveAt,
      userId,
      breakTimecardsAttributes,
    })

    return form
  }

  const simpleCurrentTime = () => {
    const { nearestTo } = JSON.parse(localStorage.getItem('simpleClockIn') || '{}')
    if (!nearestTo) return formatISO(new Date())

    return formatISO(roundToNearestMinutes(new Date(), { nearestTo }))
  }

  return {
    buildTimecardForm,
    buildTimecardEditForm,
    simpleCurrentTime,
  }
}
