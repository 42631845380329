<template>
  <div>
    <div class="d-flex flex-wrap align-center">
      <v-switch
        v-model="isOn"
        label="SIMPLE"
        hide-details
        inset
        class="pa-0 ma-0 pb-4"
        @click="$emit('is-on', isOn)"
      />

      <transition name="fadeLeft">
        <div
          v-if="isOn"
          style="animation-duration: 0.3s; width: 120px;"
          class="ms-8"
        >
          <v-select
            v-model="nearestTo"
            :items="items"
            item-text="value"
            suffix="分単位"
            class="pa-0 ma-0"
            :menu-props="{ auto: true, offsetY: true }"
          />
        </div>
      </transition>
    </div>

    <v-snackbar
      :value="showMsg"
      top
      centered
      timeout="2000"
    >
      SIMPLEモードは端末依存の設定です。この設定はこの端末のみに保存されます。
    </v-snackbar>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

export default {
  setup(_, { emit }) {
    const key = 'simpleClockIn'

    const isOn = ref(!!JSON.parse(localStorage.getItem(key) || '{}').nearestTo)
    const nearestTo = ref(JSON.parse(localStorage.getItem(key) || '{}').nearestTo)
    const showMsg = ref(false)
    const items = [
      { value: 1 },
      { value: 5 },
      { value: 10 },
      { value: 15 },
      { value: 20 },
      { value: 30 },
    ]

    watch(() => isOn.value, newVal => {
      nearestTo.value = newVal ? 1 : null
      localStorage.setItem(key, JSON.stringify({ nearestTo: nearestTo.value }))

      showMsg.value = true
    })

    watch(() => nearestTo.value, newVal => {
      if (!items.map(item => item.value).includes(newVal)) {
        isOn.value = false
        localStorage.setItem(key, JSON.stringify({ nearestTo: null }))

        return
      }

      localStorage.setItem(key, JSON.stringify({ nearestTo: newVal }))
    })

    emit('is-on', isOn.value)

    return {
      isOn,
      nearestTo,
      showMsg,
      items,
    }
  },
}
</script>
